import $ from 'jquery';

$(function () {
  $('a[href^="#"]').click(function () {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    if ($(this).data('offset'))
      var offset = $(this).data('offset');
    else
      var offset = 0;
    var position = target.offset().top - offset;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});

import AOS from 'aos';

AOS.init({
  startEvent: 'DOMContentLoaded',
  duration: 500,
});